/**
 * Allows us JS access to the safe-area-inset values.
 */
class SafeArea{
    constructor() {
        this._support = false;
    }

    /**
     * Needs to be called once we have a window object and document to work with,
     * before we can calculate whether we support the safe-area, and what the safe-area is.
     */
    initialize(){
        if (!document){
            throw("Safe Area calculation can't initialize without a document.");
        }

        if (!this._determineSupport()){
            console.log("Platform does not support css environment variables.");
            return;
        }

        const directions = ['top', 'left', 'bottom', 'right'];
        const calcEl = document.body;

        for (const dir of directions){
            calcEl.style.setProperty(`--sa${dir}`, `env(safe-area-inset-${dir})`);
        }
    }

    _determineSupport(){
        this._support = (
            'CSS' in window &&
            typeof CSS.supports == 'function' &&
            CSS.supports('padding-top: env(safe-area-inset-top)')
        );

        return this._support;
    }

    _getSafeAreaDirection(dir){
        const computed = getComputedStyle(document.body);
        return parseInt(computed.getPropertyValue(`--sa${dir}`), 10) || 0;
    }

    get supported(){
        return this._support;
    }

    get left(){
        return this._getSafeAreaDirection('left');
    }

    get right(){
        return this._getSafeAreaDirection('right');
    }

    get top(){
        return this._getSafeAreaDirection('top');
    }

    get bottom(){
        return this._getSafeAreaDirection('bottom');
    }
}

const safearea = new SafeArea();

export default safearea;
