import {library as faLib} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {faBookmark, faCircle} from "@fortawesome/free-regular-svg-icons";
import {faCanadianMapleLeaf} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

faLib.add(fas);
faLib.add(faBookmark);
faLib.add(faCircle);
faLib.add(faCanadianMapleLeaf);

export default (app) => {
    app.component('fa-i', FontAwesomeIcon);
    app.component('font-awesome-icon', FontAwesomeIcon);
    return app;
};
