<template lang="pug">
v-container(fluid, fill-height, column)
    v-row(justify-center, align-center, fill-height, row, wrap)
        v-col(cols="12")
            fa-i.yellow--text.darken-3(size="2x", icon="hard-hat")
            h2.headline -= Under Construction =-
        v-col(cols="12").mt-4
            template(v-if="isHome")
                v-btn(@click="logout", color="primary")
                    span.pr-3
                        fa-i(size="lg", icon="user-times")
                    span Logout
            template(v-else)
                v-btn(@click="goBack", color="primary")
                    span.pr-3
                        fa-i(size="lg", icon="chevron-left")
                    span Go Back

</template>

<script>
    import auth from "@/core/auth";

    export default {
        name: "UnderConstruction",
        data(){
            return {
                isHome:(this.$router.currentRoute.name === 'home'),
            };
        },
        methods:{
            goBack(){
                if (window.history.length){
                    return window.history.back();
                }

                this.$router.push({name:'home'});
            },
            logout(){
                auth.logout();
                this.$router.push({name:'login'});
            },
        },
    };
</script>

<style scoped>

</style>
