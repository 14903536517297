import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import {aliases, fa} from 'vuetify/iconsets/fa-svg';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const lightTheme = {
    light:true,
    colors:{
        primary: '#F47726',
    },
};

const darkTheme = {
    dark:true,
    colors:{
        primary: '#F47726',
    },
};

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    theme: {
        defaultTheme: "lightTheme",
        themes:{
            lightTheme,
            darkTheme,
        },
    },
});

export default vuetify;
