/**
 * Install our comm utility as a plugin in Vue.
 */

import {comm, install} from "@/utils/comm";
import auth from "@/core/auth";

// Comm configuration
// See: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
comm.configure({
    headers:{
        /**
         * Whenever we make a request, attempt to fetch the auth token to
         * include in the request to authorize the request.
         * Do not send the auth token if we're making a cross-domain request.
         * @param url
         * @param data
         * @param opts
         * @returns {string|*}
         */
        Authorization(url, data, opts){
            if (auth.token && url.origin === window.location.origin){
                return `Token ${auth.token}`;
            }

            return void 0;
        }
    },
});

export default install;
