<template lang="pug">
v-container(fluid)
    v-row(justify-center, align-center)
        v-col(cols="12")
            fa-i.primary--text.darken-3(size="3x", icon="question-circle")
            h2.headline 404: Not Found
            p.body-1 Are you lost?
    v-row()
        v-col(cols="12")
            v-btn(@click="$router.push({name:'home'})", color="primary")
                fa-i.mr-2(size="lg", icon="home")
                | Go Home

</template>

<script>
    export default {
        name: "NotFound",
    };
</script>

<style scoped>

</style>
